<template>
  <!-- 调整客户归属详情 -->
  <div class="type_area">
    <div class="content_box">
      <div class="top_title">
        <span class="title">申请人信息</span>
      </div>
      <div class="form_box">
        <div class="form_item">
          <span>申请人姓名</span>
          <span>付小小</span>
        </div>
        <div class="form_item">
          <span>申请人部门</span>
          <span>客服中心-事业部-南大区-北部</span>
        </div>
        <div class="form_item">
          <span>所属职务</span>
          <span>区域经理</span>
        </div>
        <div class="form_item">
          <span>所属省市</span>
          <span>山西省-运城市-XXX区</span>
        </div>
        <div class="form_item">
          <span>所属区域</span>
          <span>安徽省合肥市</span>
        </div>
        <div class="form_item">
          <span>所属省办</span>
          <span>安徽省</span>
        </div>
        <div class="form_item">
          <span>申请日期</span>
          <span>2024-04-29</span>
        </div>
        <div class="form_item">
          <span>入职日期</span>
          <span>2021-04-29</span>
        </div>
        <div class="form_item">
          <span>生效日期</span>
          <span>2023-04-29</span>
        </div>
      </div>
      <div class="top_title">
        <span class="title">客户明细</span>
        <div class="btn_box">
          <el-button type="primary" size="small">下载客户明细</el-button>
        </div>
      </div>
      <div class="table_mode">
        <el-table :data="tableData" v-loading="loading" stripe style="width: 100%">
          <el-table-column prop="companyName" label="客户编号" min-width="120" :show-overflow-tooltip="true" align="center" fixed="left"></el-table-column>
          <el-table-column prop="businessDepart" label="客户名称" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="地址" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="业务员" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="联系人" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="联系电话" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="businessDepart" label="企业类型" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="memberLevel" label="注册时间" min-width="150" :show-overflow-tooltip="true" align="center"></el-table-column>
          <div slot="empty" class="nodata_box">
            <img src="@/assets/images/index/nodata.png"/>
            <span class="txt">暂无数据哦~</span>
          </div>
        </el-table>
      </div>
      <div class="pagination_box">
        <el-pagination
          background
          layout="prev, pager, next, sizes, total"
          :page-sizes="[10, 20, 30, 40]"
          :page-size.sync="filterinfo.pageSize"
          :current-page.sync="filterinfo.page"
          :total="total"
          @current-change="getCustomerList"
          @size-change="getCustomerList(1)">
        </el-pagination>
      </div>
      <div class="top_title">
        <span class="title">费用明细</span>
      </div>
      <div class="form_box bottom">
        <div class="form_item">
          <span>2024-04指标(元)</span>
          <span>￥236996.00</span>
        </div>
        <div class="form_item">
          <span>2024-05指标(元)</span>
          <span>￥236996.00</span>
        </div>
        <div class="form_item">
          <span>2024-06指标(元)</span>
          <span>￥236996.00</span>
        </div>
        <div class="form_item">
          <span>2024年第一季度指标(元)</span>
          <span>￥54596996.00</span>
        </div>
        <div class="form_item">
          <span>2024年第一季度回款(元)</span>
          <span>￥3596996.00</span>
        </div>
        <div class="form_item">
          <span>2024年第一季度完成率</span>
          <span>100%</span>
        </div>
        <div class="form_item">
          <span>应收总额(元)</span>
          <span>￥9936996.00</span>
        </div>
        <div class="form_item">
          <span>超期应收(元)</span>
          <span>￥9936996.00</span>
        </div>
        <div class="form_item">
          <span>是否转正</span>
          <span>是</span>
        </div>
        <div class="form_item">
          <span>截止月份(元)</span>
          <span>2024-08</span>
        </div>
        <div class="form_item">
          <span>销售回款应收表</span>
          <span class="doc">销售回款应收表.doc</span>
        </div>
      </div>
      <div class="top_title">
        <span class="title">审批流程</span>
      </div>
      <div class="block">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :icon="activity.icon"
            :type="activity.type"
            :color="activity.color"
            :size="activity.size"
            :timestamp="activity.timestamp">
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
      </div>
      <div class="btn_box">
        <el-button class="btn_item" size="small">取消</el-button>
        <el-button class="btn_item" type="primary" size="small">撤销</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DetailAttribution',
  data() {
    return {
      loading: false,
      total: 0,
      formData: {

      },
      filterinfo:{
        page:1,
        pageSize:10,
        search: '',
        orderBy: ''
      },
      tableData:[],
      activities: [{
        content: '支持使用图标',
        timestamp: '2018-04-12 20:46',
        size: 'large',
        type: 'primary',
        icon: 'el-icon-more'
      }, {
        content: '支持自定义颜色',
        timestamp: '2018-04-03 20:46',
        color: '#0bbd87'
      }, {
        content: '支持自定义尺寸',
        timestamp: '2018-04-03 20:46',
        size: 'large'
      }, {
        content: '默认样式的节点',
        timestamp: '2018-04-03 20:46'
      }]
    };
  },
  created() {

  },
  mounted(){
    this.getCustomerList()
  },
  methods: {
    // 点击重置
    resetClick(){
      this.filterinfo = {
        page:1,
        pageSize:10,
        search: '',
        orderBy: ''
      }
      this.getCustomerList()
    },
    // 获取客户账龄列表
    getCustomerList(num){
      if(num==1){
        this.filterinfo.page = 1
      }
      let params = this.filterinfo
      this.loading = true
      this.$api.pcBillList(params).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.tableData = res.data.data.list
          this.total = res.data.data.total
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.content_box{
  padding: 20px 24px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  margin-top: 16px;
  overflow: hidden;

  .top_title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #DDDDDD;

    .title{
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;

      &::before{
        content: "";
        display: inline-block;
        width: 6px;
        height: 16px;
        background: #2554E5;
        border-radius: 8px;
        margin-right: 7px;
      }
    }
  }
  .form_box{
    margin-bottom: 6px;
    
    .form_item{
      display: inline-block;
      width: 33%;
      margin-bottom: 7px;
      font-size: 16px;
      color: #333333;

      span:first-of-type{
        display: inline-block;
        width: 97px;
        color: #666666;
      }

      .doc{
        color:#1A9DFF;
        cursor: pointer;

        &:hover{
          color: #0687FE;
        }
      }
    }

    &.bottom{
      margin-bottom: 10px;
      
      span:first-of-type{
        width: 190px;
      }
    }
  }
  .btn_box{
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn_item{
      width: 128px;
      height: 42px;
      font-size: 16px;
    }
  }
}
</style>